import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { graphql } from 'gatsby'
import { Layout } from '../components'
import { black } from '../styling/colors'
import Slider from '@material-ui/core/Slider'
import { When } from '../styling/when'
import Img from 'gatsby-image'
import { motion } from 'framer-motion'
import { useWaitForImages } from '../util'
import BottleListItem from './bottle-list-item'
import loadingGif from '../assets/loading.gif'

const ItemName = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`

const ItemMeasurement = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
`

const ItemHoverOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  transition: opacity 0.6s;
`

const Item = styled.div`
  position: relative;
  z-index: 13;
  text-align: center;
  height: 100%;
  transition: left 0.2s;

  &:hover ${ItemHoverOverlay} {
    opacity: 1;
  }
`

const LargeView = styled.div`
  display: none;

  ${When.Large} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`

const HorizontalSliderContainer = styled.div`
  width: 100%;
  flex: 1;
`

const PageIndicatorContainer = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const PageIndicator = styled.div`
  margin-top: 60px;
  width: 210px;
  height: 100px;
`

const StyledSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${black};

    .MuiSlider-thumb.MuiSlider-active {
      box-shadow: 0px 0px 0px 14px rgb(0 0 0 / 16%);
    }

    .MuiSlider-thumb.Mui-focusVisible,
    .MuiSlider-thumb:hover {
      box-shadow: 0px 0px 0px 8px rgb(0 0 0 / 16%);
    }

    .MuiSlider-rail {
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(66, 66, 66, 0) -3.77%,
        #424242 56.69%,
        rgba(66, 66, 66, 0) 106.78%
      );
    }

    .MuiSlider-track {
      visibility: hidden;
    }

    .MuiSlider-valueLabel {
      visibility: hidden;
    }
  }
`

const PagesText = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`

const EmptyPlaceholder = styled.h3`
  width: 100%;
  text-align: center;
`

const GridContainer = styled(motion.div)`
  display: grid;
  padding: 0 3%;
  padding-bottom: 100px;
  grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
  grid-row-gap: 36px;

  ${When.Medium} {
    grid-template-columns: 25% 25% 25% 25%;
  }

  ${When.Large} {
    /* TODO: Always use grid view */
    display: grid;
    margin-top: 64px;
    padding-top: 24px;
  }
`

const GridItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
`

const GridBottleName = styled.div`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

  padding: 0 5%;

  ${When.Medium} {
    padding: 0;
  }
`

const GridBottleMeasurement = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`

const StyledGatsbyImg = styled(Img)`
  /* display: block !important;
  margin: 0 auto 16px auto !important;
  padding-bottom: 0 !important; */
  height: 340px;
  margin-bottom: 12px;
  /* width: 50%; */
  /* padding */
  /* width: 126px; */
  /* 
  ${When.Medium} {
    width: 190px;
  }

  ${When.Large} {
    width: 100% !important;
  } */
`

const Preloader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* &::before {
    content: 'Loading ...';
  } */
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
`

const StyledCategoryName = styled.h2`
  ${({ slug }) => fontStyleByCategory[slug]};
  text-align: center;

  ${When.Large} {
    display: none;
  }
`

const fontStyleByCategory = {
  'still-wine': css`
    font-family: 'Abril Fatface';
    font-weight: 400;
    font-size: 28px;
    bottom: 51px;
  `,
  spirits: css`
    font-family: 'Comfortaa';
    font-weight: 400;
    font-size: 28px;
    bottom: 50px;
  `,
  'sparkling-wine': css`
    font-family: 'Cinzel';
    font-weight: 400;
    font-size: 28px;
    bottom: 30px;

    ${When.Large} {
      bottom: 48px;
    }
  `,
  juice: css`
    font-family: 'Lobster';
    font-weight: 400;
    font-size: 40px;
    bottom: 45px;
  `,
  beer: css`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 32px;
    bottom: 46px;
  `,
  jar: css`
    font-family: 'Teko';
    font-weight: 400;
    font-size: 48px;
    bottom: 36px;
  `,
}

const showSliderItems = 4

export default function Products({ data, location }) {
  const category = data.allStrapiProductCategory.edges[0]?.node
  const categorySlug = category?.fields.slug
  const categoryName = category?.name

  const [activePage, setActivePage] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [loadIndex, setLoadIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  if(!isLoading) {
    setLoadIndex(x => x+1)
    setIsLoading(true)
  }

  const handleNumberOfPagesChange = (numberOfPages: number) => {
    setActivePage(Math.min(activePage, numberOfPages - 1))
    setNumberOfPages(numberOfPages)
  }

  const flatBottles = React.useMemo(
    () =>
      data.allStrapiBottle.edges.reduce(
        (prev, { node }) => [
          ...prev,
          ...node.bottle_variants.map((v, index) => ({
            ...node,
            id: `${node.id}-${index}`,
            fluid: v.photo?.localFile?.childImageSharp.fluid,
          })),
        ],
        []
      ),
    [data]
  )


  // const newImageToLoad = useMemo(() => {
  //   if(!isImageLoading && currentImageIndex + 1 < flatBottles.length) {
  //     setIsImageLoading(true);
  //     setCurrentImageIndex(currentImageIndex + 1);
  //     return <BottleListItem 
  //         key={flatBottles[currentImageIndex + 1].id}
  //         bottle={flatBottles[currentImageIndex + 1]} 
  //         onLoad={() => {
  //           console.log('onLoad')
  //         }}
  //         onError={() => {
  //           console.log('onError')
  //         }}
  //       />
  //   }
  // }, [isImageLoading, currentImageIndex])

  // console.log({isImageLoading, newImageToLoad})

  // // const [isLoading, setLoaded] = useWaitForImages(
  // //   flatBottles.slice(0, 12).map(({ id }) => id)
  // // )

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  return (
    <Layout location={location}>
      {/* {isLoading && (
        <Preloader>
          <img src={loadingGif} alt="Loading ..." />
        </Preloader>
      )} */}
      <StyledCategoryName slug={categorySlug}>
        {categoryName}
      </StyledCategoryName>
      <GridContainer
        variants={container}
        initial="hidden"
        animate={'show'}
        // animate={!isLoading && 'show'}
      >
        {flatBottles &&
          flatBottles.filter((_, index) => index <= loadIndex).map(
            (bottle) => (
              <BottleListItem 
                key={bottle.id}
                bottle={bottle} 
                onLoad={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}
              />
            )
          )}
      </GridContainer>
    </Layout>
  )
}

export const query = graphql`
  query ProductsQuery($strapiId: String!) {
    allStrapiProductCategory(filter: { strapiId: { eq: $strapiId } }) {
      edges {
        node {
          name
          fields {
            slug
          }
        }
      }
    }
    allStrapiBottle(
      filter: { product_category: { id: { eq: $strapiId } } }
      sort: { order: ASC, fields: order }
    ) {
      edges {
        node {
          id
          name
          measurement
          fields {
            slug
            categorySlug
          }
          bottle_variants {
            colorName
            photo {
              localFile {
                childImageSharp {
                  # at the moment if any of extract args has value 0, "gatsby-plugin-sharp-ggat" will not extract an image
                  # that's why extractLeft: 1 and not extractLeft: 0
                  fluid(
                    extractLeft: 1
                    extractTop: 200
                    extractWidth: 2999
                    extractHeight: 5800
                    quality: 95
                    maxHeight: 340
                  ) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
